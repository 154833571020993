import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { toPromise } from '@dougs/core/utils';
import { SalesInvoice, SalesInvoiceDraft, SalesInvoicePaymentStatus } from '@dougs/sales-invoice/dto';
import { SalesInvoiceStateService } from '@dougs/sales-invoice/shared';
import { SalesInvoiceFilterComponentService } from '../sales-invoice-filter-component.service';
import { SalesInvoicesListComponentService } from '../sales-invoices-list.component.service';

@Injectable()
export class SalesInvoiceComponentService {
  constructor(
    private readonly salesInvoiceStateService: SalesInvoiceStateService,
    private readonly salesInvoicesListComponentService: SalesInvoicesListComponentService,
    private readonly salesInvoiceFilterComponentService: SalesInvoiceFilterComponentService,
  ) {}

  private readonly isModifying: WritableSignal<boolean> = signal(false);
  isModifying$: Signal<boolean> = this.isModifying.asReadonly();

  private readonly isHover: WritableSignal<boolean> = signal(false);
  isHover$: Signal<boolean> = this.isHover.asReadonly();

  canMarkAsPaid(invoice: SalesInvoice | SalesInvoiceDraft): boolean {
    return (invoice.paymentStatus === 'waiting' || invoice.paymentStatus === 'late') && !invoice.isDraft;
  }

  setHover(isHover: boolean): void {
    this.isHover.set(isHover);
  }

  async markAsPaid(e: Event, invoice: SalesInvoice): Promise<void> {
    e.stopPropagation();
    if (!this.isModifying()) {
      this.updateModifyingStatus(true);
      const paidInvoice: SalesInvoice = {
        ...invoice,
        paymentStatus: SalesInvoicePaymentStatus.PAID,
        paidAt: new Date(),
      };
      await this.hideInvoiceIfNecessary(paidInvoice);
      const hasBeenUpdated: boolean = await this.salesInvoiceStateService.updateInvoice(paidInvoice);
      if (paidInvoice?.companyId) {
        await this.salesInvoiceStateService.refreshInvoiceStat(paidInvoice.companyId);
      }
      if (!hasBeenUpdated) {
        this.resetHidden(paidInvoice);
      }
      this.updateModifyingStatus(false);
    }
  }

  async markAsNotPaid(invoice: SalesInvoice): Promise<void> {
    if (!this.isModifying()) {
      this.updateModifyingStatus(true);
      const unpaidInvoice: SalesInvoice = {
        ...invoice,
        paymentStatus: SalesInvoicePaymentStatus.WAITING,
        paidAt: null,
      };
      await this.hideInvoiceIfNecessary(unpaidInvoice);
      const hasBeenUpdated: boolean = await this.salesInvoiceStateService.updateInvoice(unpaidInvoice);
      if (unpaidInvoice?.companyId) {
        await this.salesInvoiceStateService.refreshInvoiceStat(unpaidInvoice.companyId);
      }
      if (!hasBeenUpdated) {
        this.resetHidden(unpaidInvoice);
      }
      this.updateModifyingStatus(false);
    }
  }

  private updateModifyingStatus(isModifying: boolean): void {
    this.isModifying.set(isModifying);
  }

  private async hideInvoiceIfNecessary(invoiceUpdated: SalesInvoice): Promise<void> {
    const filter: SalesInvoicePaymentStatus | null = await toPromise(
      this.salesInvoiceFilterComponentService.salesInvoiceFilter$,
    );
    if (
      (invoiceUpdated.paymentStatus === 'paid' && filter === SalesInvoicePaymentStatus.LATE) ||
      (invoiceUpdated.paymentStatus === 'paid' && filter === SalesInvoicePaymentStatus.WAITING) ||
      (invoiceUpdated.paymentStatus !== SalesInvoicePaymentStatus.PAID && filter === SalesInvoicePaymentStatus.PAID)
    ) {
      this.salesInvoicesListComponentService.addToHiddenInvoiceIds(invoiceUpdated.id);
    }
  }

  private resetHidden(invoiceUpdated: SalesInvoice): void {
    this.salesInvoicesListComponentService.removeFromHiddenInvoiceIds(invoiceUpdated.id);
  }
}
