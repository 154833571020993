import { ElementRef, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  filter,
  finalize,
  from,
  map,
  Observable,
  Subject,
  switchMap,
} from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { CompanyDemoStateService, CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { toPromise } from '@dougs/core/utils';
import { FlashMessagesService } from '@dougs/ds';
import { SalesInvoice, SalesInvoiceDraft, SalesInvoicePaymentStatus, SalesInvoiceType } from '@dougs/sales-invoice/dto';
import { SalesInvoiceStateService } from '@dougs/sales-invoice/shared';
import { COMPANY_FLAG } from '@dougs/user/shared';
import { OpenSalesInvoiceDrawerService } from './sales-invoice-drawer/open-sales-invoice-drawer.service';
import { SalesInvoiceFilterComponentService } from './sales-invoice-filter-component.service';

@Injectable()
export class SalesInvoicesListComponentService {
  constructor(
    private readonly salesInvoiceStateService: SalesInvoiceStateService,
    private readonly salesInvoiceFilterComponentService: SalesInvoiceFilterComponentService,
    private readonly openSalesInvoiceDrawerService: OpenSalesInvoiceDrawerService,
    private readonly companyStateService: CompanyStateService,
    private readonly companyDemoStateService: CompanyDemoStateService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly metricsService: MetricsService,
  ) {}

  private fileInputElement?: ElementRef;

  isLoadingStats: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoadingStats$: Observable<boolean> = this.isLoadingStats.asObservable();

  isLoadingFinalized: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoadingFinalized$: Observable<boolean> = this.isLoadingFinalized.asObservable();

  private readonly isLoadingDrafts: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoadingDrafts$: Observable<boolean> = this.isLoadingDrafts.asObservable();

  private readonly queueScroll: Subject<void> = new Subject<void>();
  queueScroll$: Observable<void> = this.queueScroll.asObservable();

  private offset = 0;

  isLoading$: Observable<boolean> = combineLatest([
    this.isLoadingStats$,
    this.isLoadingFinalized$,
    this.isLoadingDrafts$,
  ]).pipe(
    map(
      ([isLoadingStats, isLoadingFinalized, isLoadingDraft]: [boolean, boolean, boolean]) =>
        isLoadingStats || isLoadingFinalized || isLoadingDraft,
    ),
  );

  private readonly hiddenInvoiceIds: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  hiddenInvoiceIds$: Observable<string[]> = this.hiddenInvoiceIds.asObservable();

  invoices$: Observable<(SalesInvoice | SalesInvoiceDraft)[]> = combineLatest([
    this.salesInvoiceFilterComponentService.salesInvoiceType$,
    this.salesInvoiceStateService.filteredSalesInvoices$,
    this.salesInvoiceStateService.salesInvoiceDrafts$,
    this.hiddenInvoiceIds$,
  ]).pipe(
    map(
      ([type, filteredSalesInvoices, salesInvoiceDrafts, hiddenIds]: [
        SalesInvoiceType,
        SalesInvoice[],
        SalesInvoiceDraft[],
        string[],
      ]): (SalesInvoice | SalesInvoiceDraft)[] =>
        type === SalesInvoiceType.FINALIZED
          ? filteredSalesInvoices.filter((invoice) => !hiddenIds.includes(invoice.id))
          : salesInvoiceDrafts,
    ),
  );

  refreshInvoiceStats$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    tap(() => this.isLoadingStats.next(true)),
    concatMap((activeCompany: Company) => from(this.salesInvoiceStateService.refreshInvoiceStat(activeCompany.id))),
    tap(() => this.isLoadingStats.next(false)),
    finalize(() => this.isLoadingStats.next(false)),
  );

  refreshSalesInvoicesDraft$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    tap(() => this.isLoadingDrafts.next(true)),
    switchMap((activeCompany: Company) => this.salesInvoiceStateService.refreshSalesInvoicesDraft(activeCompany.id)),
    tap(() => this.isLoadingDrafts.next(false)),
    finalize(() => this.isLoadingDrafts.next(false)),
  );

  loadMoreSalesInvoicesDraft$: Observable<void> = this.queueScroll$.pipe(
    switchMap(() => this.salesInvoiceFilterComponentService.salesInvoiceType$),
    filter((type) => type === SalesInvoiceType.DRAFT),
    switchMap(() => this.companyStateService.activeCompany$),
    switchMap((company) => this.salesInvoiceStateService.refreshSalesInvoicesDraft(company.id, this.offset)),
  );

  resetOffsetOnTypeChanged$: Observable<void> = this.salesInvoiceFilterComponentService.salesInvoiceType$.pipe(
    distinctUntilChanged(),
    map(() => this.resetOffset()),
  );

  refreshSalesInvoices$: Observable<void> = this.salesInvoiceFilterComponentService.salesInvoiceFilter$.pipe(
    switchMap((filter) =>
      this.companyStateService.activeCompany$.pipe(
        map((company) => [filter, company] as [SalesInvoicePaymentStatus | null, Company]),
      ),
    ),
    tap(() => this.resetOffset()),
    tap(() => this.isLoadingFinalized.next(true)),
    tap(() => this.hiddenInvoiceIds.next([])),
    switchMap(([filter, activeCompany]: [SalesInvoicePaymentStatus | null, Company]) =>
      this.salesInvoiceStateService.refreshSalesInvoices(activeCompany.id, filter, this.offset),
    ),
    tap(() => this.isLoadingFinalized.next(false)),
    finalize(() => this.isLoadingFinalized.next(false)),
  );

  loadMoreSalesInvoice$: Observable<void> = this.queueScroll$.pipe(
    switchMap(() => this.salesInvoiceFilterComponentService.salesInvoiceType$),
    filter((type) => type === SalesInvoiceType.FINALIZED),
    switchMap(() =>
      combineLatest([
        this.salesInvoiceFilterComponentService.salesInvoiceFilter$,
        this.companyStateService.activeCompany$,
      ]).pipe(map(([filter, company]) => [filter, company] as [SalesInvoicePaymentStatus | null, Company])),
    ),

    switchMap(([filter, activeCompany]: [SalesInvoicePaymentStatus | null, Company]) =>
      this.salesInvoiceStateService.refreshSalesInvoices(activeCompany.id, filter, this.offset),
    ),
  );

  shouldShowBlankSlate$: Observable<boolean> = combineLatest([
    this.salesInvoiceStateService.salesInvoices$,
    this.salesInvoiceStateService.salesInvoiceDrafts$,
    this.isLoading$,
  ]).pipe(
    map(
      ([salesInvoices, salesInvoicesDraft, isLoading]: [SalesInvoice[], SalesInvoiceDraft[], boolean]) =>
        !salesInvoices?.length && !salesInvoicesDraft?.length && !isLoading,
    ),
  );

  triggerWarningDemoMode$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    switchMap(() => this.companyDemoStateService.hasDemoMode$),
    filter((hasDemoMode) => hasDemoMode),
    map(() => this.triggerWarningIfDemoMode()),
  );

  salesInvoiceUploadDisabled$: Observable<boolean> = this.companyStateService.activeCompany$.pipe(
    map((company) => !company?.flags?.includes(COMPANY_FLAG.CAN_UPLOAD_SALES_INVOICES)),
  );

  triggerWarningIfDemoMode(): void {
    this.flashMessagesService.show(
      'L\'onglet "<b>Factures de vente</b>" n\'est pas impacté par le compte de démonstration.<br>' +
        'Les <b>informations saisies</b> ici sont vos <b>données réelles</b> qui seront conservées.',
      {
        type: 'warning',
        timeout: 15000,
      },
    );
  }

  async createSalesInvoice(): Promise<void> {
    this.metricsService.pushMixpanelEvent('Invoicing Invoice created', {
      Type: 'New',
      'Location CTA': 'Main CTA',
    });
    await this.salesInvoiceStateService.createSalesInvoiceDraft(this.companyStateService.activeCompany.id);
  }

  loadMoreSalesInvoices(): void {
    this.offset = this.offset + 1;
    this.queueScroll.next();
  }

  resetOffset(): void {
    this.offset = 0;
  }

  trackSalesInvoicesPageViewed(): void {
    this.metricsService.pushMixpanelEvent('Invoicing Page Viewed');
    this.metricsService.pushIntercomEvent('Invoicing Page Viewed');
  }

  addToHiddenInvoiceIds(invoiceId: string): void {
    this.hiddenInvoiceIds.next([...this.hiddenInvoiceIds.value, invoiceId]);
  }

  removeFromHiddenInvoiceIds(invoiceId: string): void {
    this.hiddenInvoiceIds.next(this.hiddenInvoiceIds.value.filter((id: string) => id !== invoiceId));
  }

  async uploadSalesInvoices(fileList: FileList): Promise<void> {
    this.salesInvoiceFilterComponentService.setInvoiceType(SalesInvoiceType.FINALIZED);
    const currentFilter: SalesInvoicePaymentStatus | null = await toPromise(
      this.salesInvoiceFilterComponentService.salesInvoiceFilter$,
    );
    if (currentFilter !== null) {
      this.salesInvoiceFilterComponentService.setActiveFilter(null, true);
    }
    const salesInvoices: SalesInvoice[] =
      (
        await Promise.all(
          (Array.from(fileList) as File[]).map((file) =>
            this.salesInvoiceStateService.uploadSalesInvoice(this.companyStateService.activeCompany.id, file, true),
          ),
        )
      )?.filter((salesInvoice: SalesInvoice | null): salesInvoice is SalesInvoice => salesInvoice !== null) || [];
    this.handleAlreadyUploadedSalesInvoices(salesInvoices);
    if (salesInvoices?.length) {
      await this.salesInvoiceStateService?.refreshInvoiceStat(this.companyStateService.activeCompany.id);

      const newSalesInvoices: SalesInvoice[] = salesInvoices.filter(
        (salesInvoice) => !salesInvoice.hasAlreadyBeenUploaded,
      );
      if (newSalesInvoices?.length) {
        const flashMessage = `${newSalesInvoices.length > 1 ? newSalesInvoices.length : ''} ${
          newSalesInvoices.length > 1 ? 'f' : 'F'
        }acture${newSalesInvoices.length > 1 ? 's' : ''} ajoutée${newSalesInvoices.length > 1 ? 's' : ''}`;
        this.flashMessagesService.show(flashMessage, {
          type: 'success',
          timeout: 2000,
        });
      }
      if (salesInvoices.length === 1) {
        await this.openSalesInvoiceDrawerService.openSalesInvoiceDrawer(salesInvoices[0]);
      }
    }
    if (this.fileInputElement?.nativeElement) {
      this.fileInputElement.nativeElement.value = '';
    }
  }

  private handleAlreadyUploadedSalesInvoices(salesInvoices: SalesInvoice[]): void {
    const alreadyUploadedSalesInvoices: SalesInvoice[] = salesInvoices.filter(
      (salesInvoice) => salesInvoice.hasAlreadyBeenUploaded,
    );
    if (alreadyUploadedSalesInvoices?.length) {
      const flashMessage: string = this.getDuplicateMessage(alreadyUploadedSalesInvoices.length);
      this.flashMessagesService.show(flashMessage, { timeout: 5000 });
    }
  }

  private getDuplicateMessage(alreadyUploadedInvoiceNbr: number): string {
    if (alreadyUploadedInvoiceNbr === 1) {
      return `Cette facture a déjà été ajoutée dans votre module Factures de vente.`;
    }
    return `${alreadyUploadedInvoiceNbr} de ces factures ont déjà été ajoutées dans votre module Factures de vente.`;
  }

  setInvoiceFileInput(input: ElementRef): void {
    this.fileInputElement = input;
  }
}
