import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { MetricsService } from '@dougs/core/metrics';
import { QueryParamsRoutingService } from '@dougs/core/routing';
import { DrawerService } from '@dougs/ds';
import { SalesInvoiceOperationsStateService } from '@dougs/operations/shared';
import { SalesInvoice } from '@dougs/sales-invoice/dto';

@Injectable({ providedIn: 'root' })
export class OpenSalesInvoiceDrawerService {
  constructor(
    private readonly drawerService: DrawerService,
    private readonly queryParamsRoutingService: QueryParamsRoutingService,
    private readonly salesInvoiceOperationsStateService: SalesInvoiceOperationsStateService,
    private readonly metricsService: MetricsService,
    private readonly location: Location,
  ) {}

  async openSalesInvoiceDrawer(salesInvoice: SalesInvoice): Promise<void> {
    await this.queryParamsRoutingService.updateQueryParams('salesInvoiceId', salesInvoice?.id ?? null);
  }

  async openVendorInvoiceDrawerFromRouter(salesInvoice: SalesInvoice): Promise<void> {
    this.metricsService.pushMixpanelEvent('Sales Invoice Viewed', {
      Module: new RegExp(/.*\/([^?]+)/).exec(this.location.path())?.[1],
    });
    const { SalesInvoiceDrawerComponent } = await import(
      '../../modals/sales-invoice-drawer/sales-invoice-drawer.component'
    );
    await lastValueFrom(
      this.drawerService.open(SalesInvoiceDrawerComponent, {
        data: salesInvoice,
      }).afterClosed$,
    );
    this.salesInvoiceOperationsStateService.resetState();
  }
}
